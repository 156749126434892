import React from 'react';
import Segment from 'utils/segment'
import CharlesMeta from '../common/meta/CharlesMeta';
import StructuredWebPage from '../common/meta/StructuredWebPage';
import { Layout } from '../common';
import CoachDetails from '../LandingProgram/CoachDetails';
import './styles.sass';
import data from './data.json';
import Image from '../common/utils/Image';
import ReactJWPlayer from 'react-jw-player';
import playlistTime from '../LandingProgram/TimeVideos/long-trailer.json';
import playlistMaster from '../LandingProgram/MasterVideos/long-trailer.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import TestimonySection from "../TestimonySection";
import MyAccordion from "../common/MyAccordion";
import Cta from 'components/common/Cta';

const title = "Nos thérapies digitales"

const IndexPage = () => (
  <Layout
    showLinksInFooter={false}
    showReinsuranceInFooter={false}
  >
    <CharlesMeta
      data={{
        title: `${data.meta.title}`,
        description: `${data.meta.description}`,
      }}
      segmentCategory={Segment.CATEGORIES.THERAPIES}
    />
    <StructuredWebPage
      title={title}
      description={data.meta.description}
    />
    <div className="academy-landing-page">
      <div className="digital-therapy-main-div">
        <Container className="digital-therapy-top-div">
          <h1 className="">{title}</h1>
          <div className="text-center">
            Nos thérapies digitales ont été élaborées par nos experts en médecine du sommeil, en sexologie et andrologie.
          </div>
        </Container>
        <div className="programs-wrapper">
          <Container className="program-container">
            <Row className="program-row">
              <Col className="program-video-col" xs={12} xl={5}>
                <div className="program-video-col-video">
                  <ReactJWPlayer
                    playerId="master-mobile-video"
                    playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                    playlist={playlistMaster}
                  />
                </div>
              </Col>
              <Col xs={12} xl={7} className="program-text-col">
                <h2 className="h3 text-left">Programme MASTER</h2>
                <div className="h4 text-left program-text-col-subtitle">Maîtrise Appliquée du Stress et des Troubles de l'Érection</div>
                <div className="program-text-col-description">
                  Le programme MASTER est un concentré de techniques physiques et mentales qui vous
                  permettront de comprendre votre fonctionnement érectile. L'objectif ? Apprendre
                  à retrouver et à mieux gérer vos érections et réduire le stress qui pourrait
                  entacher votre sexualité.
                </div>
                <Link to="/master/" className="program-text-col-link">
                  <div>
                    Découvrir le programme MASTER
                  </div>
                  <Image filename="new-images/arrow-link.svg"
                    alt="arrow-link"
                    title="arrow link" />
                </Link>
              </Col>
            </Row>
            <Row className="program-row">
              <Col className="program-video-col" xs={12} xl={5}>
                <div className="program-video-col-video">
                  <ReactJWPlayer
                    playerId="time-mobile-video"
                    playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                    playlist={playlistTime}
                  />
                </div>
              </Col>
              <Col xs={12} xl={7} className="program-text-col">
                <h2 className="h3 text-left">Programme TIME</h2>
                <div className="h4 text-left program-text-col-subtitle">Thérapie Intensive de Maîtrise de l'Éjaculation</div>
                <div className="program-text-col-description">
                  Le programme TIME est le tout premier programme visant à régler le trouble de
                  l'éjaculation précoce de manière naturelle, via des exercices physiques et
                  mentaux, le tout sans limiter le plaisir que vous prenez lors des rapports
                  sexuels.
                </div>
                <Link to="/time/" className="program-text-col-link">
                  <div>
                    Découvrir le programme TIME
                  </div>
                  <Image filename="new-images/arrow-link.svg"
                  alt="arrow-link"
                  title="arrow link"  />
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <CoachDetails data={data.coach} />
        <TestimonySection />
        <Container className="digital-therapy-faq-section">
          <h2>Questions fréquentes</h2>
          <div className="digital-therapy-faq-subtitle">Nos spécialistes répondent à toutes vos questions</div>
          <Row>
            <Col xs={12} xl={7}>
              <MyAccordion data={data.faq.content} />
            </Col>
            <Col xs={12} md={6} xl={5} className="ml-auto mr-auto m-xl-0">
              <div className="charles-card faq-pathology-charles-card">
                <div className="faq-pathology-card-title">
                  Vous ne trouvez pas la réponse à votre question ?
                </div>
                <Link to="/faq/" className="faq-pathology-link">
                  <Image
                    filename="new-images/blue-book.svg"
                    alt="livre-bleu-pictoo"
                    title="livre bleu picto"
                  />
                  <div>FAQ complète</div>
                </Link>
                <a href="tel:0186651733" className="faq-pathology-link">
                  <Image
                    filename="new-images/blue-phone.svg"
                    alt="mobile-bleu-picto"
                    title="mobile bleu picto"
                  />
                  <div>01 86 65 17 33</div>
                </a>
                <a
                  href="mailto:contact@charles.co"
                  className="faq-pathology-link"
                >
                  <Image
                    filename="new-images/blue-mail.svg"
                    alt="mail-bleu-picto"
                    title="mail bleu picto"
                  />
                  <div>contact@charles.co</div>
                </a>
                <Cta
                  text="Consultez un médecin"
                  path="/consulter/"
                  className="new-primary-cta"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
