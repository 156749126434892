import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AcademyLink } from 'components/Start/AcademyLink';
import Section from '../../common/Section';

import './styles.sass';
import { Container, Row, Col } from 'react-bootstrap';

class CoachDetails extends React.Component {
  render() {
    return (
      <Section
        className="academy-section academy-section-coach"
        id="coach"
        style={{ backgroundColor: this.props.data.backgroundColor }}
      >
        <Container className="academy-coach-container">
          <h2>
            Le directeur scientifique
            {' '}
            {this.props.data.title}
          </h2>
          <Row className="coach-details-content">
            <Col>
              <div className="featured-doctor">
                {(this.props.data.image === 'academy/gilbert-coach-master.jpg') && (
                  <StaticImage
                    src="../../../images/academy/gilbert-coach-master.jpg"
                    alt="gilbert is talking"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                    className="featured-image"
                  />
                )}
                {(this.props.data.image === 'academy/gilbert-couch.png') && (
                  <StaticImage
                    src="../../../images/academy/gilbert-couch.png"
                    alt="gilbert is talking"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                    className="featured-image"
                  />
                )}
              </div>
            </Col>
            <Col>
              <div className="profile">
                {this.props.data.details.map((detail, index) => (
                  <div className="doctor-informations">
                    <div className="doctor-name" key={index}>
                      {detail.proTitle}
                    </div>
                    <div className="doctor-text" key={index}>
                      {detail.text}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }
}
export default CoachDetails;
